import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { eventName: String, onLoad: Boolean }

  connect() {
    if (this.hasOnLoadValue && this.onLoadValue) {
      this.emit();
    }
  }
  emit() {
    if (this.hasEventNameValue) {
      const event = new CustomEvent(this.eventNameValue, { bubbles: true, cancelable: true, details: null })
      this.element.dispatchEvent(event)
    } else {
      console.log("EventName not defined and impossible to emit")
    }
  }
}
