import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { max: Number, min: Number }
  static targets = ["container", "number", "percentage"]

  connect() {
    this.toolbar = this.element.querySelector("trix-toolbar .trix-button-row");
    this.trix = this.element.querySelector("trix-editor");

    this.toolbar.insertAdjacentHTML("beforeend", this.renderCounter);
    this.charCount = this.trix.editor.getDocument().toString().length;

    this.renderFeedback();

    let prevText;

    this.trix.addEventListener("trix-change", (_event) => {
      const newText = this.trix.editor.getDocument().toString();
      this.charCount = newText.length;

      if (!prevText) {
        prevText = newText;
      }

      if (prevText && prevText !== newText) {
        prevText = newText;
        this.renderFeedback();
      }
    })
  }

  renderFeedback(){
    this.renderCharRemaining(this.charCount);
    this.renderCirclePercentage();

    //deselect previous
    const currentSelectedRange = this.trix.editor.getSelectedRange();
    this.trix.editor.setSelectedRange([0, this.charCount + 1]);
    this.trix.editor.deactivateAttribute('strike');

    if (this.charCount < this.minLength) {
      this.renderError();
    } else if (this.charCount < this.warningLength) {
      this.renderValid();
    } else if (this.charCount <= this.maxLength) {
      this.renderWarning();
    } else {
      this.renderError();
    }

    //restore state
    this.trix.editor.setSelectedRange(currentSelectedRange);
  }

  renderValid(){
    this.containerTarget.classList.add("text-green");
    this.containerTarget.classList.remove("text-onboarding-red", "text-yellow");
  }

  renderWarning(){
    this.containerTarget.classList.add("text-yellow");
    this.containerTarget.classList.remove("text-onboarding-red", "text-green");
  }

  renderError(){
    this.containerTarget.classList.add("text-onboarding-red");
    this.containerTarget.classList.remove("text-yellow", "text-green");
    if (this.charCount > this.maxLength) {
      this.trix.editor.setSelectedRange([this.maxLength, this.charCount]);
      this.trix.editor.activateAttribute("strike");
    }
  }

  renderCharRemaining(charCount) {
    this.numberTarget.innerText = this.maxLength - charCount + 1;
  }

  renderCirclePercentage() {
    this.percentageTarget.setAttribute("stroke-dashoffset", this.svgPercentage)
  }

  get minLength() {
    return this.minValue || 0;
  }

  get maxLength() {
    return this.maxValue || 200;
  }

  get svgPercentage() {
    if (this.charCount < this.maxLength) {
      return (100 - (this.charCount / this.maxLength * 100));
    } else {
      return 0;
    }
  }

  get warningLength() {
    return this.maxLength * 0.8;
  }

  get renderCounter() {
    return `
    <div class="inline-flex items-center justify-center overflow-hidden text-green text-sm" data-char-counter-target="container">
      <span class="pr-1 hidden md:inline-block">Characters left </span>
      <div class="inline-flex items-center justify-center overflow-hidden">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="16"
            class="text-gray"
            stroke-width="1"
            stroke="currentColor"
            fill="transparent"/>
          <circle cx="17" cy="17" r="16"
            stroke="currentColor"
            stroke-width="2"
            stroke-dasharray="100"
            stroke-dashoffset="100"
            stroke-linecap="round"
            fill="transparent"
            data-char-counter-target="percentage"/>
        </svg>
        <span class="absolute" data-char-counter-target="number"></span>
      </div>
    </div>`
  }
}
