import { Controller } from "@hotwired/stimulus";

export default class SubscriptionFormController extends Controller {
  static targets = ["selectPlanRadio", "submitButton"];

  choosePlan(event) {
    this.selectAllRadios();
    this.enableForm();
  }

  selectAllRadios(event) {
    if (!this.hasSelectPlanRadioTarget) return;

    this.selectPlanRadioTargets.forEach((radio) => radio.dispatchEvent(new Event("select")));
  }

  enableForm(event) {
    if (!this.hasSubmitButtonTarget) return;

    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.add("btn-primary");
    this.submitButtonTarget.classList.remove("btn-disabled-primary");
  }
}
