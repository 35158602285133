import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.innerHTML = this.greeting();
  }

  // private

  // Morning 5 am to 12 pm (noon)
  // Afternoon 12 pm to 5 pm.
  // Evening 5 pm to 4 am.
  greeting() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours()
    if (currentHour < 5) {
      return "Good evening";
    } else if (currentHour < 12) {
      return "Good morning";
    } else if (currentHour < 17) {
      return "Good afternoon";
    } else {
      return "Good evening"
    }
  }
}
