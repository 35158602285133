import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['item']
  static classes = ['hidden']
  static values = { clickOutside: Boolean }

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';
    if (this.hasClickOutsideValue && this.clickOutsideValue) {
      useClickOutside(this);
    }
  }

  clickOutside(e) {
    this.addClass();
  }

  removeClass(e) {
    if (e) { e.preventDefault() }
    this.itemTargets.forEach(item => {
      let toggleableClass = this.class
      if ("toggleableClass" in item.dataset) {
        toggleableClass = item.dataset.toggleableClass;
      }
      item.classList.remove(toggleableClass)
    });
  }

  addClass(event) {
    if (event) {
      if (event.target.dataset.toggleKeepDefaults === "true") {
        console.log("keeping defaults")
      } else {
        event.preventDefault()
      }
    }
    this.itemTargets.forEach(item => {
      let toggleableClass = this.class
      if ("toggleableClass" in item.dataset) {
        toggleableClass = item.dataset.toggleableClass;
      }
      item.classList.add(toggleableClass)
    });
  }

  toggleClass(event) {
    if (event) {
      if (event.target.dataset.toggleKeepDefaults === "true" ||
        event.currentTarget.dataset.toggleKeepDefaults === "true") {
        console.log("keeping defaults")
      } else {
        event.preventDefault()
      }
    }
    this.itemTargets.forEach(item => {
      let toggleableClass = this.class
      if ("toggleableClass" in item.dataset) {
        toggleableClass = item.dataset.toggleableClass;
      }
      item.classList.toggle(toggleableClass)
    });
  }
}
