import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class FlashMessageController extends Controller {
  static values = {
    delay: Number,
    hidden: { type: Boolean, default: false },
  }

  connect() {
    useTransition(this)

    if (this.hiddenValue === false) this.show();
  }

  disconnect() {
    this.hide()
  }

  show() {
    this.enter()

    if(this.hasDelayValue) this.timeout = setTimeout(this.hide.bind(this), this.delayValue);
  }

  async hide() {
    if (this.timeout) clearTimeout(this.timeout);

    await this.leave()

    this.element.remove()
  }
}
