import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  handleInput() {
    this.element.value = this.element.value.toLowerCase()
  }
}
