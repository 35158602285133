import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ["list"]

  connect() {
    console.log("Connected to ArraySortableController")
    this.sortable = Sortable.create(this.listTarget, {
      // handle: '.handle',
      animation: 150,
      onEnd: this.persist.bind(this)
    })
  }

  async persist(event) {
    const resourceId = this.element.dataset.resourceId
    const fromIndex = event.oldIndex
    const toIndex = event.newIndex

    try {
      const response = await fetch(`/superadmin/ideal_customers/${resourceId}/reorder_titles`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': this.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ from_index: fromIndex, to_index: toIndex })
      })

      if (!response.ok) {
        // Revert the drag if the server request failed
        this.sortable.sort(Array.from(this.listTarget.children).sort((a, b) =>
          parseInt(a.dataset.position) - parseInt(b.dataset.position)
        ))
      }
    } catch (error) {
      console.error('Error updating order:', error)
    }
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
