import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];
  static classes = ["active", "inactive"];

  change(event) {
    event.preventDefault();

    const tabName = event.currentTarget.dataset.tabName;
    if (tabName) this._showTabContent(tabName);

    this.tabTargets.forEach((tab) => {
      tab.classList.remove(...this.activeClasses);
      tab.classList.add(...this.inactiveClasses);
    });

    event.currentTarget.classList.remove(...this.inactiveClasses);
    event.currentTarget.classList.add(...this.activeClasses);
  }

  _showTabContent(tabName) {
    this.contentTargets.forEach((tab) => {
      tab.classList.toggle("hidden", tab.dataset.tabName !== tabName);
    });
  }
}
