import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["condition", "hideable"]
  static values = { shouldShow: Boolean, showOnce: Boolean }

  connect() {
    this.updateHideableVisibility()
  }

  updateHideableVisibility() {
    if (this.showOnceValue && this.shouldShowValue) {
      return;
    }

    this.shouldShowValue = this.conditionTargets.some((condition) => {
      const conditionType = condition.dataset.conditionType
      const conditionValue = condition.dataset.conditionValue
      return this.evaluateCondition(condition, conditionType, conditionValue)
    })
  }

  shouldShowValueChanged() {
    this.hideableTargets.forEach((hideable) => {
      if (this.shouldShowValue) {
        hideable.classList.remove("hidden")
      } else {
        hideable.classList.add("hidden")
      }
    })
  }

  evaluateCondition(condition, conditionType, conditionValue) {
    switch (conditionType) {
      case "valuePresent":
        return typeof(condition.value) !== "undefined" && condition.value !== "";
      case "valueEquals":
        return condition.value === conditionValue
      case "valueIn":
        return conditionValue.split(",").includes(condition.value)
      case "valueLessThan":
        return parseInt(condition.value, 10) < parseInt(conditionValue, 10)
      case "valueGreaterThan":
        return parseInt(condition.value, 10) > parseInt(conditionValue, 10)
      default:
        return false
    }
  }
}
