import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    mustScroll: Boolean,
    targetId: String,
    useJavascript: Boolean,
  }

  connect() {
    if (this.hasMustScrollValue && this.mustScrollValue) {
      if (this.hasUseJavascriptValue && this.useJavascriptValue) {
        const target = this.element.querySelector(this.targetIdValue)
        target.scrollIntoView();
      } else {
        location.href = "#";
        location.href = this.targetIdValue;
      }
    }
  }
}
