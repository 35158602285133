import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'
import { isMobile } from './utils'

import 'swiper/swiper-bundle.css';
export default class extends Controller {
  static values = {
    options: Object,
    fullWidth: Boolean,
    onlyMobile: Boolean,
  }

  connect() {
    document.addEventListener('turbo:load', () => {
      if (this.hasOnlyMobileValue) {
        if (isMobile()) {
          this.initSwipper();
        }
      } else {
        this.initSwipper();
      }
    });
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy();
    }
    this.swiper = undefined
  }

  initSwipper() {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    });

    if (!isMobile()) {
      this.setWidthToContainer(this.distanceToWindowBorder());
    }
  }

  resize() {
    // this.destroyOnDesktop();
    // this.setWidthToContainer(this.distanceToWindowBorder());
  }

  // Private methods

  get defaultOptions() {
    return {
      slidesPerView: 1,
      loop: false,
      mousewheel: { forceToAxis: true },
      // watchOverflow: true,
    }
  }

  get fadeOptions() {
    return {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    }
  }

  destroyOnDesktop() {
    if (this.swiper && this.onlyMobileValue && !isMobile()) {
      this.swiper.destroy();
    } else {
      this.initSwipper();
    }
  }

  setWidthToContainer(width = 'auto') {
    // Only set width if swiper must reach the window border
    if (this.fullWidthValue) {
      this.element.style.width = width;
    }
  }

  distanceToWindowBorder() {
    // Get the width from the element starting point to the window border
    return document.body.clientWidth - this.element.offsetLeft + 'px';
  }
}
