import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";
// import { Sortable, MultiDrag, Swap, OnSpill, AutoScroll } from "sortablejs";

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      animation: 150,
      onEnd: this.persist.bind(this)
    })
  }

  // private

  persist(event) {
    const resourceType = event.item.dataset.resourceType;
    const resourceId = event.item.dataset.resourceId;
    const url = `/admin/sortings/${resourceId}`
    fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': this.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "position": event.newIndex + 1, "type": resourceType })
    });
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
