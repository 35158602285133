import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["marker", "message"]
  static values = {
    lastRead: Number,
    url: String,
    userId: String,
  }

  connect() {
    this.checkAndUpdate()
  }

  checkAndUpdate() {
    let updatePending = true;
    let markerPending = true;
    this.messageTargets.every((message) => {
      const createdAt = message.dataset.chatroomReaderCreatedAtTargetValue
      if (createdAt > this.lastReadValue) {
        if (updatePending) {
          this.updateLastRead();
          updatePending = false;
        }
        if (this.userIdValue != message.dataset.chatroomReaderCreatedByTargetValue) {
          message.insertAdjacentHTML("afterbegin", this.markerTarget.innerHTML);
          markerPending = false;
        }
        if (!updatePending && !markerPending) { return false; }

      }
      return true
    })
  }

  // private

  updateLastRead() {
    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': this.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({ })
    });
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
