import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["country", "locality", "region", "street", "streetNumber", "postalCode"]
  static values = { placeType: String }

  suggestPlaces(event) {
    const options = { types: [this.placeType] };
    const autocomplete = new google.maps.places.Autocomplete(event.target, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const locationRawData = {}
      place.address_components.forEach(element => {
        locationRawData[element['types'][0]] = element['long_name']
      })
      this.localityTarget.value = locationRawData['locality']
      this.regionTarget.value = locationRawData['administrative_area_level_1']
      this.countryTarget.value = locationRawData['country']
      if (this.hasStreetTarget) { this.streetTarget.value = locationRawData['route'] }
      if (this.hasStreetNumberTarget) { this.streetNumberTarget.value = locationRawData['street_number'] }
      if (this.hasPostalCodeTarget) { this.postalCodeTarget.value = locationRawData['postal_code'] }
    });
  }

  // private

  get placeType() {
    return this.hasPlaceTypeValue ? this.placeTypeValue : "(cities)"
  }
}
