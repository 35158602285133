import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "navbar", "limit" ]

  connect() {
  }

  onScroll() {
    let top = this.limitTarget.getBoundingClientRect().top;
    this.navbarTarget.classList.add("transition-transform");
    this.navbarTarget.classList.toggle(
      "-translate-y-36", top > 0
    )
  }
}
