import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['iframe', 'iframeContainer', 'desktopButton', 'mobileButton']
  static values = {
    'mobile-path': String,
    'desktop-path': String,
    'mobile-width': String,
  }

  connect() {
    this.width = this.hasMobileWidthValue ? this.mobileWidthValue : 'w-96'
  }

  showMobile(e) {
    e.preventDefault();
    this.desktopButtonTarget.classList.remove("text-blue");
    this.mobileButtonTarget.classList.add("text-blue");
    this.iframeContainerTarget.classList.add(this.width);
    this.iframeTarget.src = this.mobilePathValue;
  }

  showDesktop(e) {
    e.preventDefault();
    this.desktopButtonTarget.classList.add("text-blue");
    this.mobileButtonTarget.classList.remove("text-blue");
    this.iframeContainerTarget.classList.remove(this.width);
    this.iframeTarget.src = this.desktopPathValue;
  }
}
