import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { sentences: Array }

  connect() {
    this.typewritter();
  }

  // private

  typewritter() {
    let arrayPosition = 0;
    let textPosition = 0;
    let sleeperPosition = 0;


    const interval = setInterval(() => {
      if (textPosition <= this.sentencesValue[arrayPosition].length) {
        this.element.innerHTML = this.sentencesValue[arrayPosition].substring(0, textPosition) +  "<span class=\"text-gray-darker animate-ping\">|</span>"
        textPosition++;
      } else if (sleeperPosition++ >= 25) {
        textPosition = 0;
        sleeperPosition = 0;
        if (arrayPosition++ >= this.sentencesValue.length - 1) {
          arrayPosition = 0;
        }
      }
    }, 100)
  }

}
