import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  perform(event) {
    event.preventDefault();

    // Determine the URL based on whether the element is a form or a link
    let url = this.element.tagName === "FORM" ? this.element.action : this.element.href;

    // If the element is a form, serialize the form data and append it to the URL
    if (this.element.tagName === "FORM") {
      const formData = new FormData(this.element);
      const queryString = new URLSearchParams(formData).toString();
      url = `${url}?${queryString}`;
    }


    const newWindow = window.open(url, "_blank");

    if (newWindow) {
      console.log("Download started");

      // Add a short delay before calling Turbo.visit()
      setTimeout(() => {
        console.log("Reloading page");
        Turbo.visit(window.location.href);
      }, 500);
    } else {
      console.log("Failed to start download");
    }
  }
}
