import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "togglerContainer", "showMoreTemplate", "showLessTemplate" ]
  static values = { lines: Number, open: Boolean }

  initialize() {
    this.originalHeights = [];
    this.shortHeights = [];
    this.contentTargets.forEach((content, index) => {
      content.classList.add('overflow-hidden', 'transition-all');
      this.originalHeights[index] = this.totalHeight(content);
      this.shortHeights[index] = this.lessHeight(content);
      const container = content.parentNode.querySelector('[data-multiple-show-more-target="togglerContainer"]')
      if (this.originalHeights[index] > this.shortHeights[index]) {
        if (this.openValue) {
          container.innerHTML = this.showLessTemplateTarget.innerHTML;
        } else {
          container.innerHTML = this.showMoreTemplateTarget.innerHTML;
          content.style.height = this.shortHeights[index] + "px";
        }
      } else {
        container.parentNode.removeChild(container);
      }
    });
  }
  connect() {

  }

  toggle(event) {
    event.preventDefault();
    this.openValue = !this.openValue;
  }

  openValueChanged() {
    this.contentTargets.forEach((content, index) => {
      if (this.openValue) {
        content.style.height = this.originalHeights[index] + "px";
      } else {
        content.style.height = this.shortHeights[index] + "px";
      }
    });

    if (this.hasTogglerContainerTarget) {
      this.togglerContainerTargets.forEach((toggle_container) => {
        if (this.openValue) {
          toggle_container.innerHTML = this.showLessTemplateTarget.innerHTML;
        } else {
          toggle_container.innerHTML = this.showMoreTemplateTarget.innerHTML;
        }
      });
    }
  }

  lessHeight(content) {
    return parseFloat(getComputedStyle(content).lineHeight, 10) * this.linesValue;
  }

  totalHeight(content) {
    return content.clientHeight;
    // return parseFloat(getComputedStyle(content).height, 10);
  }
}
