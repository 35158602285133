import { Controller } from "@hotwired/stimulus";

export default class CheckboxClassSwitcherController extends Controller {
  static targets = ["item", "checkbox"];

  checkboxTargetConnected(element) {
    this.switchClasses({ target: element });
  }

  switchClasses(event) {
    if (!this.hasItemTarget) return;

    if (event.target.checked) {
      this.itemTargets.forEach(this.check);
    } else {
      this.itemTargets.forEach(this.uncheck);
    }
  }

  check(item) {
    const uncheckedClasses = item.dataset.checkboxClassSwitcherUncheckedClass?.split(" ") || [];
    if (uncheckedClasses.length) item.classList.remove(...uncheckedClasses);

    const checkedClasses = item.dataset.checkboxClassSwitcherCheckedClass?.split(" ") || [];
    if (checkedClasses.length) item.classList.add(...checkedClasses);
  }

  uncheck(item) {
    const checkedClasses = item.dataset.checkboxClassSwitcherCheckedClass?.split(" ") || [];
    if (checkedClasses.length) item.classList.remove(...checkedClasses);

    const uncheckedClasses = item.dataset.checkboxClassSwitcherUncheckedClass?.split(" ") || [];
    if (uncheckedClasses.length) item.classList.add(...uncheckedClasses);
  }
}
