import { Controller } from "@hotwired/stimulus"

/*
 * This controllers copies target checked checkboxes to the a form before submitting.
 * Ex: At the selected contacts screen, the user can select multiple contacts using the checkboxes
 * and then choose to export them to a CSV file or send them to Zapier and each of these option are
 * different forms.
 *
 * USAGE:
 *  1. Set the checkboxes as target: data { copy_checked_checkboxes_target: "input" }
 *  2. Trigger the handleSubmit action at the forms: data: { action: "submit->copy-checked-checkboxes#handleSubmit" }
*/
export default class CopyCheckedCheckboxesController extends Controller {
  static targets = ["input"]

  handleSubmit(e) {
    this.checkedCheckboxes().forEach((input) => {
      const newInput = this.copyInputFrom(input)
      e.target.appendChild(newInput)
    })
  }

  checkedCheckboxes() {
    return this.inputTargets.filter((input) => input.checked)
  }

  copyInputFrom(originalInput) {
    const newInput = document.createElement("input")
    newInput.type = "hidden"
    newInput.name = originalInput.name
    newInput.value = originalInput.value
    return newInput
  }
}
