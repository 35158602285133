import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { duration: Number }
  static targets = [ "status", "errorMessage" ]

  connect() {
    this.timeout = null
    this.duration = this.hasDurationValue ? this.durationValue : 1000
    const definedMethod = this.element.querySelector('input[name="_method"]')
    this.method = definedMethod ? definedMethod.value.toUpperCase() : this.element.method
  }

  save() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "Saving..."

      // Create a URL-encoded string from the form data
      const formData = new FormData(this.element);
      const urlEncodedData = new URLSearchParams();

      formData.forEach((value, key) => {
        urlEncodedData.append(key, value);
      });

      fetch(this.element.action, {
        method: this.method,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: urlEncodedData.toString()
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === "ok") {
          this.success()
        } else {
          this.error(data.errors)
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.error()
      });
    }, this.duration)
  }

  success() {
    this.statusTarget.textContent = "Saved!"
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = "";
    }

    setTimeout(() => {
      this.statusTarget.textContent = ""
    }, 3000)
  }

  error(errors) {
    this.statusTarget.textContent = "Unable to save!"
    if (errors && this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = errors
    }

    setTimeout(() => {
      this.statusTarget.textContent = ""
    }, 3000)
  }
}
