import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { action: String, key: String, disabled: Boolean }
  static targets = ["input", "submit"]

  connect() {
    if (this.disabledValue) return;

    this.submitTarget.disabled = true;
    const timer = setInterval(() => {
      if (typeof(grecaptcha) !== "undefined") {
        this.submitTarget.disabled = false;
        clearInterval(timer);
      }
    }, 3000);
  }

  getToken(event) {
    if (this.disabledValue) return;

    event.preventDefault();
    this.submitTarget.disabled = true;
    this.submitTarget.value = "Sending ..."

    if (typeof(grecaptcha) !== "undefined") {
      grecaptcha.enterprise.ready(async () => {
        await grecaptcha.enterprise.execute(this.keyValue, {action: this.actionValue})
          .then((token) => {
            this.inputTarget.value = token;
            event.target.form.requestSubmit();
          });
      });
    }
  }
}
