import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static values = { currentColor: String }

  connect() {
  }

  changeColor(event) {
    this.currentColorValue =  event.target.options[event.target.selectedIndex].value;
  }

  currentColorValueChanged() {
    this.elementTargets.forEach(element => {
      if (element.dataset.changeColorAttribute == "border-color") {
        element.style.borderColor = this.currentColorValue;
      } else {
        element.style.color = this.currentColorValue;
      }
    })
  }
}
