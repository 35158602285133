import { Controller } from "@hotwired/stimulus";

export default class PlaygroundFormController extends Controller {
  static targets = [
    "leadCheckbox",
    "selectedCounter",
    "submitButton",
    "minSelectedLeadsMissing",
    "minSelectedLeadsReached",
  ];

  static values = {
    minSelectedLeads: Number,
  };

  connect() {
    this.recalculateSelectedCounter();
  }

  recalculateSelectedCounter() {
    if (!this.hasSelectedCounterTarget) return;

    this.selectedLeadsCount = this.leadCheckboxTargets.filter((c) => c.checked)?.length || 0;
    this.selectedCounterTarget.innerHTML = this.selectedLeadsCount;

    if (!this.hasMinSelectedLeadsValue) return;
    if (this.selectedLeadsCount < this.minSelectedLeadsValue) {
      this.disableForm();
    } else {
      this.enableForm();
    }
  }

  enableForm() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.add("btn-primary");
      this.submitButtonTarget.classList.remove("btn-disabled-primary");
    }

    if (this.hasMinSelectedLeadsMissingTarget) {
      this.minSelectedLeadsMissingTarget.classList.add("hidden");
    }

    if (this.hasMinSelectedLeadsReachedTarget) {
      this.minSelectedLeadsReachedTarget.classList.remove("hidden");
    }
  }

  disableForm() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.remove("btn-primary");
      this.submitButtonTarget.classList.add("btn-disabled-primary");
    }

    if (this.hasMinSelectedLeadsMissingTarget) {
      this.minSelectedLeadsMissingTarget.classList.remove("hidden");
    }

    if (this.hasMinSelectedLeadsReachedTarget) {
      this.minSelectedLeadsReachedTarget.classList.add("hidden");
    }
  }
}
