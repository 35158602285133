import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content" ]
  static values = { textLess: String, lines: Number }

  connect() {
    document.addEventListener('turbo:load', () => {
      this.contentTarget.classList.add('overflow-hidden', 'transition-all');
      this.originalHeight = this.totalHeight();
      this.shortHeight = this.lessHeight();
      this.contentTarget.style.height = this.shortHeight + "px";
    });
  }

  toggle(event) {
    event.preventDefault();
    const button = event.currentTarget;
    this.originalText ||= button.innerText;

    if (button.innerText == this.originalText) {
      this.contentTarget.style.height = this.originalHeight + "px";
      event.currentTarget.innerText = this.textLessValue;
    } else {
      this.contentTarget.style.height = this.shortHeight + "px";
      event.currentTarget.innerText = this.originalText;
    }
  }

  lessHeight() {
    return parseFloat(getComputedStyle(this.contentTarget).lineHeight, 10) * this.linesValue;
  }

  totalHeight() {
    return this.contentTarget.clientHeight;
    // return parseFloat(getComputedStyle(this.contentTarget).height, 10);
  }
}
