import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "emptyContent", "selectedContent", "selectedFileName"];
  static classes = ["hover"];

  connect() {
    this.element.addEventListener("dragenter", this._preventDefault.bind(this));
    this.element.addEventListener("dragover", this._setHover.bind(this));
    this.element.addEventListener("dragleave", this._clearHover.bind(this));
    this.element.addEventListener("drop", this._handleDrop.bind(this));
  }

  inputChanged(e) {
    if (e.target.value) {
      this._showSelectedContent(e.target.files[0]);
    } else {
      this._showEmptyContent();
    }
  }

  _showSelectedContent(selectedFile) {
    this.selectedContentTargets.forEach((el) => el.classList.remove("hidden"));
    this.emptyContentTargets.forEach((el) => el.classList.add("hidden"));

    if (this.hasSelectedFileNameTarget) {
      this.selectedFileNameTarget.innerHTML = selectedFile.name;
    }
  }

  _showEmptyContent() {
    this.selectedContentTargets.forEach((el) => el.classList.add("hidden"));
    this.emptyContentTargets.forEach((el) => el.classList.remove("hidden"));
  }

  _setHover(e) {
    e.preventDefault();
    this.element.classList.add(...this.hoverClasses);
  }

  _preventDefault(e) {
    e.preventDefault();
  }

  _clearHover(e) {
    this.element.classList.remove(...this.hoverClasses);
  }

  _handleDrop(e) {
    e.preventDefault();

    this.inputTarget.files = e.dataTransfer.files;
    this._showSelectedContent(e.dataTransfer.files[0]);
    this._clearHover();
  }
}
