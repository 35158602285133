import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["observed", "affected"];

  connect() {
    this.resizeObserver = new ResizeObserver(this.handleResize.bind(this));
    this.resizeObserver.observe(this.observedTarget);
  }

  disconnect() {
    this.resizeObserver.disconnect();
  }

  handleResize(entries) {
    for (let entry of entries) {
      const newWidth = entry.contentRect.width;
      this.affectedTarget.style.marginLeft = `${newWidth}px`;
    }
  }
}
