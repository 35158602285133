import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitting', 'active']

  connect() {
    this.element.addEventListener('turbo:submit-start', this._submitting.bind(this))
  }

  _submitting() {
    console.log('submitting')

    if (this.hasActiveTarget) {
      this.activeTargets.forEach((target) => target.classList.add('hidden')) 
    }

    if (this.hasSubmittingTarget) {
      this.submittingTargets.forEach((target) => target.classList.remove('hidden')) 
    }
  }

}
