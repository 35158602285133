import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    action: String,
    delay: Number,
  }

  connect() {
    if (this.hasActionValue) {
      switch (this.actionValue) {
        case 'remove':
          setTimeout(() => { this.element.parentElement.removeChild(this.element); }, this.delay)
          break;
        case 'enable':
          let counter = this.delay / 1000;
          const originalText = this.element.value;
          this.element.value = originalText + " (" + counter + ")"
          const timer = setInterval(() => {
            if (counter > 0) {
              counter -= 1;
              this.element.value = originalText + " (" + counter + ")"
            } else {
              this.element.value = originalText;
              this.element.disabled = false;
              this.element.classList.remove("cursor-not-allowed")
              this.element.classList.add("cursor-pointer")
              clearInterval(timer);
            }
          }, 1000);
          break;
        default:
          break;
      }
    }
  }

  // private

  get delay() {
    return this.hasDelayValue ? this.delayValue : 300
  }
}
