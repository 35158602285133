import { Controller } from "@hotwired/stimulus"
import { useIntersection, useMutation } from 'stimulus-use'
import { Howl } from 'howler';


export default class extends Controller {
  static classes = [ "buttonIcon", "progressColor", "inactive" ]
  static targets = [ "output", "input", "button", "audiowave" ]

  connect() {
    useMutation(this, { attributes: true, attributeFilter: ["src"] })
    useIntersection(this, { threshold: 0.25, rootMargin: "4000px 0px 4000px 0px" })
  }

  disconnect() {
    if (this.audio) {
      this.audio.unload();
    }
  }

  disappear(_entry) {
    if (this.audio) {
      this.pauseAudio();
    }
  }

  mutate(entries) {
    entries[0].target.classList.remove(this.inactiveClass);
  }

  togglePlay(e) {
    e.preventDefault();
    this.src = this.element.getAttribute("src");

    if (this.src) {
      this.audio ||= new Howl({
        src: this.src,
        html5: true,
        onplay: () => {
          requestAnimationFrame(this.step.bind(this));
        },
        onstop: () => {
          this.stopAudio();
        },
        onend: () => {
          this.stopAudio();
        }
      });
      this.buttonTarget.style.opacity = 1;

      if (this.audio.playing()) {
        this.pauseAudio();
      } else {
        this.playAudio();
      }
    }
  }

  step() {
    // // Determine our current seek position.
    var position = this.audio.seek() || 0;
    const rectIndex = parseInt(((position / this.audio.duration()) * this.rects.length) || 0);
    for (var i = 0; i < rectIndex; i++) {
      this.rects[i].setAttribute("class", this.progressColor);
    }

    // // If the sound is still playing, continue stepping.
    if (this.audio.playing()) {
      requestAnimationFrame(this.step.bind(this));
    }
  }

  pauseAudio() {
    this.audio.pause();
    this.buttonTarget.innerHTML = this.playIcon;
  }

  playAudio() {
    this.audio.play();
    this.buttonTarget.innerHTML = this.pauseIcon;
  }

  stopAudio() {
    this.buttonTarget.innerHTML = this.playIcon;
    Array.from(this.rects).forEach(rect => rect.setAttribute("class", "currentColor"))
  }

  get rects() {
    if (this.hasAudiowaveTarget) {
      return this.audiowaveTarget.querySelectorAll("rect");
    } else {
      return []
    }
  }

  get progressColor() {
    return this.hasProgressColorClass ? this.progressColorClass : "text-blue";
  }

  get inactiveClass() {
    return this.hasInactiveClass ? this.inactiveClass : "opacity-40";
  }

  get playIcon() {
    return `<svg width="11" height="13" class="${this.buttonIconClass}" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 5.63298C11.1667 6.01788 11.1667 6.98013 10.5 7.36503L2.25 12.1282C1.58333 12.5131 0.75 12.0319 0.75 11.2621L0.750001 1.73587C0.750001 0.966065 1.58333 0.484941 2.25 0.869841L10.5 5.63298Z" fill="currentColor"/></svg>`;
  }

  get pauseIcon() {
    return `<svg width="14" height="18" class="${this.buttonIconClass}" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="5.17026" height="17.2342" rx="2" fill="currentColor"/><rect x="8.61694" width="5.17026" height="17.2342" rx="2" fill="currentColor"/></svg>`
  }
}
