import { Controller } from "@hotwired/stimulus";
/*

This controller enables selecting or unselecting all or multiple checkboxes on the page

Targets:
@parent - Parent checkbox for select All/None
@child  - Multiple child checkboxes
*/
export default class extends Controller {
  static targets = ['parent', 'child', 'selectedCount', 'selectAction', 'unselectAction']
  static values = { count: Number }

  connect () {
    this.setCount()
  }

  childTargetConnected(e) {
    e.checked = this.parentTarget.checked
  }

  toggleSelection() {
    if (!this.hasParentTarget) return

    for (let child of this.childTargets) {
      child.checked = this.parentTarget.checked;
      child.dispatchEvent(new Event('change', { bubbles: true }));
    }

    this.setCount()
  }

  selectAll() {
    for (let child of this.childTargets) {
      if (!child.checked) {
        child.checked = true;
        child.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }

    this.sync()
  }

  unSelectAll() {
    for (let child of this.childTargets) {
      if (child.checked) {
        child.checked = false;
        child.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }

    this.sync()
  }


  sync() {
    if (this.allChildrenChecked() && this.childTargets.length != 0) {
      this.parentTarget.indeterminate = false
      this.parentTarget.checked = true
    } else if (this.childTargets.length === 0) {
      this.parentTarget.checked = false
      this.parentTarget.indeterminate = false
    } else {
      this.parentTarget.checked = false
      this.parentTarget.indeterminate = this.someChildrenChecked()
    }

    this.parentTarget.dispatchEvent(new Event('change', { bubbles: true }));

    this.setCount()
  }

  allChildrenChecked() {
    return this.checkedChildrenTargets().length === this.childTargets.length
  }

  someChildrenChecked() {
    return this.checkedChildrenTargets().length > 0;
  }

  noChildrenChecked() {
    return this.checkedChildrenTargets().length == 0
  }

  parentChecked() {
    return this.parentTarget.checked == true
  }

  checkedChildrenTargets() {
    return this.childTargets.filter(child => child.checked)
  }

  setCount() {
    this.countValue = this.checkedChildrenTargets().length


    if (this.hasSelectedCountTarget) {
      this.selectedCountTarget.innerHTML = this.checkedChildrenTargets().length
    }

    if (this.hasTotalCountTarget) {
      this.totalCountTarget.innerHTML = this.childTargets.length
    }
  }

  countValueChanged() {
    let someChildrenChecked = this.someChildrenChecked()

    if (someChildrenChecked) {
      this.selectActionTargets.forEach(target => {
        target.classList.remove("hidden")
      })
      this.unselectActionTargets.forEach(target => {
        target.classList.add("hidden")
      })
    } else {
      this.selectActionTargets.forEach(target => {
        target.classList.add("hidden")
      })
      this.unselectActionTargets.forEach(target => {
        target.classList.remove("hidden")
      })
    }
  }
}
