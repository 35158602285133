import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    setTimeout(() => {
      this.markAsRead();
    }, 5000)
  }

  markAsRead() {
    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': this.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({ })
    });
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
