import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tabsWrapper', 'tabContent', 'toggleButton']
  static values = { locked: Boolean }

  connect() {
    if(!this.lockedValue) this.setCollapsed(this.isCollapsed())
  }

  changeActiveGroup(event) {
    event.preventDefault();

    this.setCollapsed(false);

    const groupName = event.currentTarget.dataset.tabGroup;
    if (groupName) this.showTabContent(groupName);
  }

  showTabContent(groupName) {
    this.tabContentTargets.forEach(tab => {
      tab.classList.toggle('hidden', tab.dataset.tabGroup !== groupName)
    })
  }

  isCollapsed() {
    return sessionStorage.getItem("collapsed") === 'true';
  }

  toggleVisibility() {
    this.setCollapsed(!this.isCollapsed())
  }

  setCollapsed(isCollapsed) {
    sessionStorage.setItem("collapsed", isCollapsed);
    this.tabsWrapperTarget.classList.toggle('hidden', isCollapsed)

    if (this.hasToggleButtonTarget) {
      if(isCollapsed) {
        this.toggleButtonTarget.classList.add('-right-4', 'rotate-180')
      } else {
        this.toggleButtonTarget.classList.remove('-right-4', 'rotate-180')
      }
    }
  }
}
