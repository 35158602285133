import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'item']
  static values = { targetValue: String, presenceShow: Boolean }

  connect() {
    this.privateToggle();
  }

  toggle(_event) {
    this.privateToggle();
  }

  privateToggle() {
    this.inputTargets.forEach(input => {
      if (input.value === this.targetValueValue) {
        this.toggleClass(input);
      }
    });
  }

  toggleClass(input) {
    if (input.checked) {
      // Present
      if (this.hasPresenceShowValue && this.presenceShowValue) {
        this.showAndEnable();
      } else {
        this.hideAndDisable();
      }
    } else {
      if (this.hasPresenceShowValue && this.presenceShowValue) {
        this.hideAndDisable();
      } else {
        this.showAndEnable();
      }
    }
  }

  showAndEnable() {
    this.itemTargets.forEach(item => {
      item.classList.remove("hidden")
      item.querySelectorAll('input').forEach(input => {
        input.disabled = false
      })
    });
  }

  hideAndDisable() {
    this.itemTargets.forEach(item => {
      item.classList.add("hidden")
      item.querySelectorAll('input').forEach(input => {
        input.disabled = true
      })
    });
  }
}
