import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "pattern", "subject" ]

  connect() {
    setTimeout(() => {this.resize()}, 100)
    this.resize();
  }

  resize() {
    this.subjectTargets.forEach(subject => {
      subject.style.width = `${this.patternTarget.clientWidth}px`;
    })
  }
}
