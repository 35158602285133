import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "departmentSelect",
    "targetPositionVariantsSelect",
    "businessAreaKeywordsWrapper"
  ];

  connect(e) {
    setTimeout(() => this._changeDepartment(this.departmentSelectTarget.value), 300)
  }

  async handleDepartmentChange(event) {
    this._changeDepartment(event.target.value);
  }

  async _changeDepartment(departmentId) {
    this._toggleBusinessAreaKeywordsVisibility(departmentId)

    if (!departmentId) return;

    this._removeSystemTargetPositionVariants();

    const newOptions = await this._buildNewTargetPositionVariants(departmentId);

    newOptions.forEach((option) => {
      this.targetPositionVariantsTomeselect.addOption(option);
      this.targetPositionVariantsTomeselect.addItem(option.value);
    });
  }

  async _fetchNewTargetPositionVariants(departmentId) {
    const url = "/admin/ideal_customers/department_target_position_variants.json?department_id=" + departmentId;
    return await fetch(url, {
      method: "GET", headers: { "Accept": "application/json" },
    }).then((response) => response.json());
  }

  async _buildNewTargetPositionVariants(departmentId) {
    const variants = await this._fetchNewTargetPositionVariants(departmentId);
    if (!variants || !variants.length) return [];

    return variants.map((name) => ({ value: name, text: name, system: true }));
  }

  _removeSystemTargetPositionVariants() {
    const currentOptions = Object.values(this.targetPositionVariantsTomeselect.options);
    if (!currentOptions || !currentOptions.length) return;

    const systemOptions = currentOptions.filter((option) => option.system);
    systemOptions.forEach((option) => {
      this.targetPositionVariantsTomeselect.removeItem(option.value);
      this.targetPositionVariantsTomeselect.removeOption(option.value);
    });
  }

  _toggleBusinessAreaKeywordsVisibility(departmentId) {
    if(departmentId) {
      this.businessAreaKeywordsWrapperTarget.classList.add("hidden")
    } else {
      this.businessAreaKeywordsWrapperTarget.classList.remove("hidden")
    }
  }

  get targetPositionVariantsTomeselect() {
    return this.targetPositionVariantsSelectTarget.tomselect;
  }
}
