import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]

  usePlaceholder(event) {
    const parentNode = event.target.parentNode
    parentNode.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)
    parentNode.removeChild(event.target)
  }
}
