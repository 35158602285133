import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['textLabel', 'booleanLabel', 'input']
  static values = { checkedLabel: String, uncheckedLabel: String }

  connect() {
    if (this.inputTarget.checked) {
      this.markAsChecked();
    }
  }

  toggle() {
    this.textLabelTarget.classList.toggle("text-red-300")
    this.textLabelTarget.classList.toggle("text-blue")
    this.textLabelTarget.innerText = this.toggleText(this.textLabelTarget.innerText)
  }

  markAsChecked() {
    this.textLabelTarget.classList.remove("text-red-300")
    this.textLabelTarget.classList.add("text-blue")
    this.textLabelTarget.innerText = this.checkedLabel
  }

  // private

  toggleText(text) {
    return text == this.checkedLabel ? this.uncheckedLabel : this.checkedLabel
  }

  get checkedLabel() {
    return this.hasCheckedLabelValue ? this.checkedLabelValue : "Published"
  }

  get uncheckedLabel() {
    return this.hasUncheckedLabelValue ? this.uncheckedLabelValue : "Unpublished"
  }
}
