import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = { name: String, properties: Object, trackView: Boolean }

  connect() {
    // Use existing properties or an empty object if none are provided
    this.properties = this.hasPropertiesValue ? this.propertiesValue : {};

    // If trackViewValue is true, add url and title to the properties and track event
    if (this.trackViewValue) {
      this.properties = {
        ...this.properties,
        url: window.location.href,
        title: document.title,
      };
      this.track();
    }
  }

  track() {
    ahoy.track(this.nameValue, this.properties);
  }
}
