import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  handleChange(event) {
    const selectedOption = event.target.value;
    if (selectedOption) {
      Turbo.visit(selectedOption);
    }
  }
}
