import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["copier", "toggler"]

  copy(event) {
    this.copierTargets.forEach(copier => {
      if (copier.dataset.copyCatSignature == event.target.dataset.copyCatSignature) {
        copier.innerText = event.target.value;
      }
    })
    this.togglerTargets.forEach(toggler => {
      if (toggler.dataset.copyCatSignature == event.target.dataset.copyCatSignature) {
        const displayStyle = event.target.value.length > 0 ? 'flex' : `none`
        toggler.style.display = displayStyle;
      }
    })
  }
}
