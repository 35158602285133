import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['container'];

  connect() {
  }

  disconnect() {
    this.close();
  }

  open(e) {
    e.preventDefault();
    e.target.blur();

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.containerTarget.classList.remove('hidden', 'opacity-0');
  }

  close(e) {
    // if (e) {
    //   e.preventDefault();
    // }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.containerTarget.classList.add('hidden', 'opacity-0');
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    // const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    // Add classes to body to fix its position
    document.body.classList.add('overflow-hidden');
  }

  unlockScroll() {
    document.body.classList.remove('overflow-hidden');
  }
}
