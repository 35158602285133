// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const head = document.querySelector("head")
import * as Sentry from '@sentry/browser';
if (head && ["production", "staging"].includes(head.dataset.railsEnv)) {
  Sentry.init({
    dsn: 'https://8d8986361a564c1c9621e4f44041f455@o933974.ingest.sentry.io/5938083',
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /widget.freshworks/,
    ],
  });
  // Set user information, as well as tags and further extras
  if (head.dataset.userId) {
    Sentry.configureScope(scope => {
      // scope.setExtra('battery', 0.7);
      // scope.setTag('user_mode', 'admin');
      scope.setUser({ id: head.dataset.userId });
      // scope.clear();
    });
  }
}

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "stylesheets/application"
import ahoy from "ahoy.js";
import "chartkick/chart.js"
import "form-request-submit-polyfill"

window.ahoy = ahoy;

require("trix");
require("@rails/actiontext")

Rails.start()
ActiveStorage.start()

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

const segmentMeta = document.querySelector("meta[name='segment-key")
if (segmentMeta != null) {
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=`${segmentMeta.content}`;;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load(`${segmentMeta.content}`);
  }}();
}

document.addEventListener('turbo:load', () => {
  // Segment
  const userMeta = document.querySelector("meta[name='current-user")
  if (segmentMeta != null && userMeta != null) {
    analytics.identify(
      userMeta.content.padStart(5, "0"),
      {
        email: userMeta.dataset.email,
        first_name: userMeta.dataset.firstName,
        last_name: userMeta.dataset.lastName,
        subscription: userMeta.dataset.subscription,
        role: userMeta.dataset.role,
        testing_account: userMeta.dataset.testingAccount,
      }
    )
  }
  analytics.page();
});

document.addEventListener("turbo:before-render", function() {
  Chartkick.destroyAll();
});

document.addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const containerElement = toolbarElement.querySelector(".trix-dialog--link")
  containerElement.insertAdjacentHTML('beforeend', '<div><p class="text-gray-400 text-xs mt-2"> Must be a valid URL starting with http:// or https://</p></div>')
})
