import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template", "container" ]

  addAssociation(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace( /TEMPLATE_RECORD_INDEX/g, new Date().valueOf() );
    this.containerTarget.insertAdjacentHTML('beforeend', content);
  }

  removeAssociation(event) {
    event.preventDefault();
    const item = event.target.closest("." + this.data.get("class-name"));
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
  }
}
