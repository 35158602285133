import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = { name: String, properties: Object, trackVisit: Boolean }

  connect() {
    if (this.trackVisitValue) {
      this.track();
    }
  }

  track(event) {
    ahoy.track(this.nameValue, this.propertiesValue);
  }

  get properties() {
    return this.hasPropertiesValue ? this.propertiesValue : {}
  }
}
