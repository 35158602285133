import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "hideable", "switchable"]
  static classes = ['hidden']
  static values = { active: Number, switchables: Array }

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';
    this.hideAndShow();
    this.switch();
  }

  activate(e) {
    if (this.activeValue == this.mainItem(e.target).dataset.index) {
      this.activeValue = null;
    } else {
      this.activeValue = this.mainItem(e.target).dataset.index;
    }
  }

  activeValueChanged() {
    this.hideAndShow();
    this.switch();
  }

  switch() {
    this.switchableTargets.forEach(switchable => {
      if (this.mainItem(switchable).dataset.index == this.activeValue) {
        switchable.innerText = this.switchablesValue[1]
      } else {
        switchable.innerText = this.switchablesValue[0]
      }
    })
  }

  hideAndShow() {
    this.hideableTargets.forEach(hideable => {
      if (this.mainItem(hideable).dataset.index == this.activeValue) {
        hideable.classList.remove(this.class)
      } else {
        hideable.classList.add(this.class)
      }
    })
  }

  mainItem(subitem) {
    return subitem.closest("div[data-accordion-target='item']")
  }
}
