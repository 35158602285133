import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { copy: String }

  connect() {
    this.copy = this.copyValue || "Saving..."
  }

  disable(event) {
    if (event.submitter) {
      event.submitter.disabled = true
      event.submitter.value = this.copy
    }
  }
}
