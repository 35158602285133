import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autoremove: Number }

  connect() {
    if (this.hasAutoremoveValue) {
      this.timer = setTimeout(() => { this.remove(); }, parseInt(this.autoremoveValue, 0));
    }
  }

  remove(event) {
    // clear timeout if any
    if (event) { clearTimeout(this.timer);}
    this.element.parentNode.removeChild(this.element);
  }
}
