import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { checked: Boolean }
  static classes = ['hidden']
  static targets = ["input", "item", "disableItem"]

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';
    if (this.checkedValue) {
      this.itemTargets.forEach(item => {
        this.hideAndDisable(item)
      });
    }
  }

  toggle() {
    this.checkedValue = !this.checkedValue
  }

  hideAndDisable(item) {
    item.classList.add(this.toggleableClass(item))
    item.querySelectorAll('input').forEach(input => {
      input.disabled = true
    })
  }

  showAndEnable(item) {
    item.classList.remove(this.toggleableClass(item))
    item.querySelectorAll('input').forEach(input => {
      input.disabled = false
    })
  }

  checkedValueChanged() {
    this.itemTargets.forEach(item => {
      if (this.checkedValue) {
        this.hideAndDisable(item)
      } else {
        this.showAndEnable(item)
      }
    });
  }

  // private

  toggleableClass(item) {
    if ("toggleableClass" in item.dataset) {
      return item.dataset.toggleableClass;
    } else {
      return this.class;
    }
  }

  get checkedLabel() {
    return this.hasCheckedLabelValue ? this.checkedLabelValue : "Published"
  }

  get uncheckedLabel() {
    return this.hasUncheckedLabelValue ? this.uncheckedLabelValue : "Unpublished"
  }
}
