import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["copyable"]
  static values = { text: String }

  copy() {
    document.addEventListener('copy', this.copyHandler.bind(this));
    document.execCommand('copy');
    document.removeEventListener('copy', this.copyHandler.bind(this));
  }

  copyHandler(event) {
    event.preventDefault();

    if (event.clipboardData) {
      event.clipboardData.setData('text/html', this.copyableValue);
      event.clipboardData.setData('text/plain', this.copyableValue);
    } else {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(this.copyableValue);
        return;
      }
      navigator.clipboard.writeText(this.copyableValue).then(() => {
        console.log('Async: Copying to clipboard was successful!');
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    }
  }

  // Private

  get copyableValue() {
    const copyableTarget = this.firstVisibleCopyableTarget()
    if (copyableTarget) {
      return copyableTarget.innerHTML
    } else {
      return this.textValue
    }
  }

  firstVisibleCopyableTarget() {
    return this.copyableTargets.find(target => target.offsetParent !== null)
  }

  fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement("textarea")
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const result = document.execCommand('copy') ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + result);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
}
