import { Controller } from "@hotwired/stimulus";
import noUiSlider from "nouislider";

export default class NoUiSlider extends Controller {
  static targets = ["rangeSlider", "minDisplay", "minInput", "maxDisplay", "maxInput", "customPip"];

  static values = {
    settings: Object,
  };

  static classes = ["pipActive", "pipInactive"];

  rangeSliderTargetConnected(el) {
    const settings = this.settingsValue;

    if (settings.format == "to_i") {
      settings.format = {
        to: (value) => String(value),
        from: (value) => parseInt(value),
      };
    }

    noUiSlider.create(el, settings);

    el.noUiSlider.on("update", ([min, max]) => {
      if(!this.hasCustomPipTarget) return;

      this.customPipTargets.forEach((pip) => {
        if(Number(pip.dataset.noUiCustomPipValue) <= min) {
          pip.classList.add(...this.pipActiveClasses)
          pip.classList.remove(...this.pipInactiveClasses)
        } else {
          pip.classList.remove(...this.pipActiveClasses)
          pip.classList.add(...this.pipInactiveClasses)
        }
      });

    })

    el.noUiSlider.on("change", ([min, max]) => {
      if (this.hasMinDisplayTarget) this.minDisplayTarget.innerHTML = Number(min).toFixed(0);
      if (this.hasMinInputTarget) this.minInputTarget.value = min;

      if (max) {
        if (this.hasMaxDisplayTarget) this.maxDisplayTarget.innerHTML = Number(max).toFixed(0);
        if (this.hasMaxInputTarget) this.maxInputTarget.value = max;
      }

      el.dispatchEvent(new CustomEvent("change", { bubbles: true, cancelable: true }));
    });
  }
}
