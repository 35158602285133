import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable", "slideable" ]

  show(event) {
    // if (event) { event.preventDefault(); }
    this.slideableTarget.classList.remove("translate-x-full")
    this.slideableTarget.classList.add("translate-x-0")
    this.hideableTarget.classList.remove("hidden")
  }

  close(event) {
    if (event) { event.preventDefault(); }
    this.slideableTarget.classList.remove("translate-x-0")
    this.slideableTarget.classList.add("translate-x-full")
    this.hideableTarget.classList.add("hidden")
    const frame = document.querySelector("turbo-frame#slideover")
    frame.innerHTML = ""
  }
}
