import { Controller } from "@hotwired/stimulus";
import flatpickr from 'flatpickr';

export default class extends Controller {

  connect() {
    let config = '{}'
    const attributes = this.element.dataset.attributes;
    if (attributes) {
      config = attributes
    }
    this.flatpickrInstance = flatpickr(this.element, JSON.parse(config));
  }

  disconnect() {
    this.flatpickrInstance.destroy();
  }
}
