import { Controller } from "@hotwired/stimulus";
import tippy, { roundArrow } from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/dist/svg-arrow.css'; // optional roundArrow
import 'tippy.js/animations/scale.css';

export default class extends Controller {
  initialize() {
    tippy.setDefaultProps({
      delay: 50,
      arrow: roundArrow,
      allowHTML: true,
      placement: 'top',
      offset: [0, 4],
      onCreate: this.autoshow.bind(this),
      onShown: this.autohide.bind(this),
    })
  }

  connect() {
    this.initializeTippy();
  }

  disconnect() {
    if (this.element._tippy) this.element._tippy.destroy();
  }

  autohide(instance) {
    if (this.element.hasAttribute('data-tippy-autohide')) {
      let duration = parseInt(this.element.getAttribute('data-tippy-autohide'), 0);
      setTimeout(() => {
        instance.hide();
      }, duration);
    }
  }

  autoshow(instance) {
    if (this.element.hasAttribute('data-tippy-autoshow')) {
      let duration = parseInt(this.element.getAttribute('data-tippy-autoshow'), 0);
      setTimeout(() => {
        instance.show();
      }, duration);
    }
  }

  initializeTippy() {
    // destroy existing tippy instance if it exists
    if (this.element._tippy) this.element._tippy.destroy();

    // Check for the data-tippy-mobile attribute
    const disableOnMobile = this.data.has('mobile') && this.data.get('mobile') === 'false';

    // Only initialize tippy if it's not a mobile device or if tippy isn't disabled on mobile for this element
    if(!disableOnMobile || (disableOnMobile && window.innerWidth > 768)) {
        tippy(this.element);
    }
  }
}
