import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['hidden']
  static values = { userId: Number, action: String }

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';
    if (this.actionValue == "show") {
      if (this.owner()) {
        this.element.classList.remove(this.class)
      } else {
        this.element.classList.add(this.class)
      }
    } else {
      if (this.owner()) {
        this.element.classList.add(this.class)
      } else {
        this.element.classList.remove(this.class)
      }
    }
  }

  // private

  owner() {
    return this.currentUserId === this.userIdValue;
  }

  get currentUserId() {
    return parseInt(document.querySelector("head").dataset.userId);
  }
}
