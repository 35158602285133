import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "legend", "scrollable" ]

  connect() {
    setTimeout(() => {
      if (this.scrollableTarget.scrollHeight > this.scrollableTarget.clientHeight) {
        this.legendTarget.classList.toggle("hidden")
      }
    },100)
  }
}
