import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    condition: Boolean,
    delay: Number,
    intersection: Boolean,
    clearOnDisconnect: Boolean
  }

  connect() {
    if (this.hasConditionValue && this.conditionValue) {
      this.timeout = setTimeout(() => { this.click(); }, this.delay)
    }
    if (this.hasIntersectionValue && this.intersectionValue) {
      this.timeout = setTimeout(() => { this.lazyClick(); }, this.delay)
    }
  }

  disconnect() {
    if (this.timeout && this.hasClearOnDisconnectValue && this.clearOnDisconnectValue) {
      clearTimeout(this.timeout);
    }
  }

  click() {
    this.element.click()
  }

  lazyClick () {
    const options = {
      threshold: 1,
      rootMargin: '0px'
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.element.click()
          observer.unobserve(entry.target)
        }
      })
    }, options)

    observer.observe(this.element)
  }

  // private

  get delay() {
    return this.hasDelayValue ? this.delayValue : 300
  }
}
