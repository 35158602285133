import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.classList.add("pl-7", "pr-12")
  }

  format() {
    this.formatCurrency()
  }

  blur() {
    this.formatCurrency(true)
  }

  formatCurrency(blur) {
    let input_value = this.element.value;
    if (input_value === "") { return; }

    let caret_position = this.element.selectionStart;
    const original_length = input_value.length;
    const decimal_position = input_value.indexOf(".");

    if (decimal_position >= 0) {

      let left_side = input_value.substring(0, decimal_position);
      let right_side = input_value.substring(decimal_position);

      if (left_side.length == 0) {
        left_side = "0"
      } else {
        left_side = this.formatNumber(left_side);
      }

      right_side = this.formatNumber(right_side);
      if (blur) {
        right_side += "00";
      }
      right_side = right_side.substring(0, 2);

      input_value = left_side + "." + right_side;

    } else {
      input_value = this.formatNumber(input_value);

      if (blur) {
        input_value += ".00";
      }
    }
    // send updated string to input
    this.element.value = input_value;

    const updated_length = input_value.length;
    caret_position = updated_length - original_length + caret_position;
    this.element.setSelectionRange(caret_position, caret_position);
  }

  formatNumber(number) {
    // format number 1000000 to 1,234,567
    return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}
